<template>
<b-container>
  <div class="pt-5">
    <div class="row">
      <div class="col-md-6 col-md-6 col-sm-12 pb-5" v-if="company">
        <b-img :src="company.banners" fluid></b-img>
        <h3 class="mt-4">{{ company.name.th }}</h3>
        <p><strong>Contact</strong></p>
        <ul>
          <li>Website: {{ company.website }}</li>
          <li>Tel: {{ company.tel }}</li>
        </ul>
        <br />
        <p>{{ company.descriptions.th }}</p>
      </div>
      <hr />
      <div class="col-md-6 col-md-6 col-sm-12  pb-5">
        <div class="row mb-5 justify-content-center">
          <h5 class="title">
            งานทั้งหมดของบริษัท
          </h5>
          <div class="input-group">
            <input type="text" class="form-control" v-model="search">
            <div class="input-group-append">
              <button class="btn btn-primary-light pr-4 pl-4" type="button" @click="filterJobs">
                <img src="@/assets/svg/search.svg" style="width: 18px;"> ค้นหา
              </button>
            </div>
          </div>
        </div>
        <b-card no-body class="overflow-hidden mb-4" style="max-width: 540px;"
        v-for="(job, index) in filterJobs.slice(0, 15)"
          :key="index">
          <b-row no-gutters>
            <b-col md="4" class="mb-md-auto mt-auto flex p-10">
              <img
                class="img-logo p-3"
                v-if="job.company && job.company.banners"
                :src="job.company && job.company.banners"
                :alt="job.title.th"
              />
            </b-col>
            <b-col md="8">
              <b-card-body :title="job.title.th">
                <b-card-text>
                  <p class="card-text">{{ company.name.th }}</p>
                </b-card-text>
                <div>
                  <img src="@/assets/images/business.png" size="12" class="p-1"><span>: {{ job.salary.value }}</span> 
                </div>
                <b-row>
                  <div class="col-md-6 col-12">
                    <img src="@/assets/images/signs.png" size="12" class="p-1"><span>: {{ job.place.th }}</span> 
                  </div>
                  <router-link :to="`/detailjobs/${job._id}`" class="col-md-6 col-12 text-right">
                    <button class="btn btn-primary btn-sm jobs">รายละเอียด</button>
                  </router-link>
                </b-row>
              </b-card-body>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </div>
  </div>
</b-container>
</template>

<script>
import { getCompanyById, getCompanyJobs } from "@/services/company.service";
export default {
  data() {
    return {
      company: null,
      jobs: [],
      search: "",
    };
  },
  mounted() {
    this.loadCompany();
    this.loadJobs();
  },
  computed: {
    companyId() {
      return this.$route.params.id;
    },
    filterJobs() {
      return this.jobs.filter((job) => {
        return job.title.th.toLowerCase().includes(this.search.toLowerCase())
        || job.title.en.toLowerCase().includes(this.search.toLowerCase());
      })
    }
  },
  methods: {
    async loadCompany() {
      const { data } = await getCompanyById(this.companyId);
      this.company = data;
    },
    async loadJobs() {
      const { data } = await getCompanyJobs(this.companyId);
      this.jobs = data;
    },
  },
};
</script>

<style lang="scss" scoped>
span {
  font-size: 15px;
}
.title {
  display: flex;
  align-items: center;
  margin: 10px;
}
.card {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 11px;
  font-size: 1rem;
}
.text-limit {
  height: 4.4em;
  overflow: hidden;
}
.img-logo {
  max-width: 250px;
  max-height: 150px;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: auto;
}
@media (max-width: 789px) {
  .img-logo {
    max-width: 100%;
  }
}
button.jobs {
  height: 40px;
  width: 120px;
}
.input-group {
  width: 100%;
  padding: 0 1rem;
}
h4 {
  font-size: 1.2rem;
}
</style>
